import {
  AccountLocation,
  Guid,
  MaintenancePlanDefinition,
  PaymentMethod,
  RenewalTypeOption,
} from '@breezy/shared'
import { Button, Form } from 'antd'
import classNames from 'classnames'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import useIsMobile from '../../../hooks/useIsMobile'
import { MaintenancePlanCardHeader } from '../components/MaintenancePlanCardHeader'
import { RightLinkButton } from '../components/RightLinkButton'
import { SectionHeaderText } from '../components/SectionHeaderText'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import { WithLocationJobs } from '../MaintenancePlanV3Utils'
import { ConfigureBillingInfoSection } from './ConfigureBillingInfoSection'
import { ConfigureImportInfoSection } from './ConfigureImportInfoSection'
import { ConfigureVisitsSection } from './ConfigureVisitsSection'

interface ConfigureMaintenancePlanPanelProps {
  selectedLocationGuid: Guid
  selectedPlanType: MaintenancePlanDefinition
  selectedAccountLocation: WithLocationJobs<AccountLocation>
  goToSelectPlanTypeStep: () => void
  onSave: () => void
  refetch: () => void
  renewalTypeOption: RenewalTypeOption | undefined
  hiddenPaymentMethods?: PaymentMethod[]
}

export const ConfigureMaintenancePlanPanel =
  memo<ConfigureMaintenancePlanPanelProps>(
    ({
      selectedLocationGuid,
      selectedPlanType,
      selectedAccountLocation,
      goToSelectPlanTypeStep,
      onSave,
      refetch,
      renewalTypeOption,
      hiddenPaymentMethods,
    }) => {
      const isMobile = useIsMobile()
      const {
        watch,
        formState: { errors },
      } = useFormContext<ConfigureMaintenancePlanFormData>()
      const isImportedPlan = watch('isImportedPlan')
      const visitsErrors = errors.visits
      return (
        <div>
          <Form layout="vertical">
            <div
              className={classNames(
                'space-between mb-4 flex w-full flex-1 flex-row items-center align-middle',
              )}
            >
              <SectionHeaderText text="Configure Plan" />
              <RightLinkButton
                onClick={goToSelectPlanTypeStep}
                title={isMobile ? 'Change' : 'Change Plan'}
              />
            </div>
            <SectionedCard
              accentBarColor={
                selectedPlanType.flare?.primaryColorHex ?? '#13C2C2'
              }
              sections={[
                {
                  coloredIn: false,
                  verticalPaddingClassName: 'py-6',
                  content: (
                    <MaintenancePlanCardHeader
                      maintenancePlanDefinition={selectedPlanType}
                    />
                  ),
                },
                isImportedPlan ? (
                  <ConfigureImportInfoSection />
                ) : (
                  <ConfigureBillingInfoSection
                    renewalTypeOption={renewalTypeOption}
                    hiddenPaymentMethods={hiddenPaymentMethods}
                  />
                ),
                <ConfigureVisitsSection
                  selectedLocationGuid={selectedLocationGuid}
                  selectedAccountLocation={selectedAccountLocation}
                  selectedPlanType={selectedPlanType}
                  refetch={refetch}
                />,
                <>
                  {visitsErrors?.root && (
                    <div className="py-4 text-red-500">
                      {visitsErrors.root.message}
                    </div>
                  )}
                  <Button
                    type="primary"
                    className="w-full"
                    onClick={onSave}
                    size="large"
                    disabled={!!visitsErrors}
                  >
                    Save & Review Plan
                  </Button>
                </>,
              ]}
            />
          </Form>
        </div>
      )
    },
  )
