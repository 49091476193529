import {
  AccountGuid,
  AccountLocationWithoutMaintenancePlans,
  AddressGuid,
  isNullish,
  LocalDate,
  Location,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import { LocationCard } from '../../../components/Cards/LocationCard'
import CreateOrEditNewAccountLocationForm from '../../../components/CreateOrEditNewAccountLocationForm/CreateOrEditNewAccountLocationForm'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  convertLocationToLocationCardLocation,
  LocationsCollapsible,
} from '../../../components/collapsibles/LocationsCollapsible/LocationsCollapsible'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { DocumentType, gql } from '../../../generated/user'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { LocationIcon } from '../../../utils/feature-icons'
import { useModalState, useQueryParamFlag } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const ACCOUNT_LOCATIONS_QUERY = gql(/* GraphQL */ `
  query AccountLocations($accountGuid: uuid!, $limit: Int!, $offset: Int!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountLocationsAggregate {
        aggregate {
          count
        }
      }
      mailingAddressGuid
      accountLocations(
        orderBy: [
          { isBillingAddress: DESC }
          { isArchived: ASC }
          { createdAt: DESC }
          { updatedAt: DESC }
        ]
        limit: $limit
        offset: $offset
      ) {
        location {
          locationGuid
          address {
            addressGuid
            line1
            line2
            stateAbbreviation
            zipCode
            city
          }
          companyGuid
          displayName
          estimatedBuildDate
          estimatedSquareFootage
          propertyType
          municipality
        }
        accountGuid
        companyGuid
        isArchived
        isBillingAddress
      }
    }
  }
`)

type AccountLocationsQuery = DocumentType<typeof ACCOUNT_LOCATIONS_QUERY>

const convertQueryToAccountLocations = (
  accountLocations: NonNullable<
    AccountLocationsQuery['accountsByPk']
  >['accountLocations'],
): AccountLocationWithoutMaintenancePlans[] => {
  return accountLocations.map(al => ({
    ...al,
    location: {
      ...al.location,
      estimatedBuildDate: al.location.estimatedBuildDate
        ? LocalDate.parse(al.location.estimatedBuildDate)
        : undefined,
    },
  }))
}

export const useFetchAccountLocations = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountLocationsQuery = useQuery({
    query: ACCOUNT_LOCATIONS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const total = useMemo(() => {
    return (
      accountLocationsQuery[0].data?.accountsByPk?.accountLocationsAggregate
        ?.aggregate?.count ?? 0
    )
  }, [accountLocationsQuery])

  const accountLocations = useMemo(() => {
    return convertQueryToAccountLocations(
      accountLocationsQuery[0].data?.accountsByPk?.accountLocations ?? [],
    )
  }, [accountLocationsQuery])

  const locations = useMemo(() => {
    return accountLocations.map(al => al.location)
  }, [accountLocations])

  const billingAddressGuid = useMemo(() => {
    return accountLocationsQuery[0].data?.accountsByPk?.mailingAddressGuid
  }, [accountLocationsQuery])

  return {
    accountLocationsQuery,
    refetch: accountLocationsQuery[1],
    fetching: accountLocationsQuery[0].fetching,
    accountLocations,
    locations,
    total,
    billingAddressGuid,
  }
}

const useEditLocationProps = (refetch: () => void) => {
  const [editingLocation, setEditingLocation] = useState<Location | undefined>(
    undefined,
  )

  const [
    upsertLocationDrawerOpen,
    openUpsertLocationDrawer,
    closeUpsertLocationDrawer,
  ] = useModalState()

  const message = useMessage()

  const onEditLocation = useCallback(
    (location: Location) => {
      setEditingLocation(location)
      openUpsertLocationDrawer()
    },
    [openUpsertLocationDrawer],
  )

  const onSuccess = useCallback(() => {
    closeUpsertLocationDrawer()
    if (!editingLocation) {
      message.success('Successfully added new Location')
    } else {
      message.success('Successfully updated Location')
    }
    setEditingLocation(undefined)
    refetch()
  }, [closeUpsertLocationDrawer, editingLocation, message, refetch])

  const onClose = useCallback(() => {
    setEditingLocation(undefined)
    closeUpsertLocationDrawer()
  }, [setEditingLocation, closeUpsertLocationDrawer])

  return {
    onEditLocation,
    editingLocation,
    onSuccess,
    onClose,
    upsertLocationDrawerOpen,
    openUpsertLocationDrawer,
  }
}

const useUpdateBillingAddress = (
  accountGuid: AccountGuid,
  refetch: () => void,
) => {
  const message = useMessage()
  const billingAddressUpsertMutation =
    trpc.accounts['account-billing-address:upsert'].useMutation()

  return useCallback(
    (addressGuid: AddressGuid) => {
      billingAddressUpsertMutation.mutate(
        {
          accountGuid,
          mailingAddressGuid: addressGuid,
        },
        {
          onSuccess() {
            refetch()
            message.success('Successfully updated billing address')
          },
        },
      )
    },
    [billingAddressUpsertMutation, accountGuid, refetch, message],
  )
}

type AccountLocationsCollapsibleProps = {
  accountGuid: AccountGuid
  editable?: boolean
  onViewMore?: () => void
}

export const AccountLocationsCollapsible =
  React.memo<AccountLocationsCollapsibleProps>(
    ({ editable = false, accountGuid, onViewMore }) => {
      const { locations, total, refetch, billingAddressGuid } =
        useFetchAccountLocations(accountGuid, {
          limit: DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
          offset: 0,
        })
      const [viewMoreOpen, viewMoreLocations, closeViewMoreLocations] =
        useQueryParamFlag('viewMoreLocations')

      const { onEditLocation, upsertLocationDrawerOpen, ...editLocationProps } =
        useEditLocationProps(refetch)
      const onBillingAddressSet = useUpdateBillingAddress(accountGuid, refetch)
      return (
        <>
          <LocationsCollapsible
            locations={locations}
            mode="paginated"
            total={total}
            billingAddressGuid={billingAddressGuid}
            onLocationAdd={
              editable ? editLocationProps.openUpsertLocationDrawer : undefined
            }
            onLocationMakeBillingAddress={location =>
              onBillingAddressSet(location.address.addressGuid)
            }
            onEdit={editable ? onEditLocation : undefined}
            onViewMore={onViewMore ?? viewMoreLocations}
          />
          {editable && upsertLocationDrawerOpen && (
            <CreateOrEditNewLocationDrawer
              accountGuid={accountGuid}
              {...editLocationProps}
            />
          )}
          {viewMoreOpen && (
            <AccountLocationsViewMoreModal
              accountGuid={accountGuid}
              editable={editable}
              onClose={closeViewMoreLocations}
            />
          )}
        </>
      )
    },
  )

type AccountLocationsViewMoreModalProps = {
  accountGuid: AccountGuid
  editable?: boolean
  onClose: () => void
}

const AccountLocationsViewMoreModal =
  React.memo<AccountLocationsViewMoreModalProps>(
    ({ accountGuid, onClose: externalOnClose, editable }) => {
      const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
        usePagination('10', 1)

      const { locations, total, fetching, billingAddressGuid, refetch } =
        useFetchAccountLocations(accountGuid, { limit, offset })
      const { onEditLocation, upsertLocationDrawerOpen, ...editLocationProps } =
        useEditLocationProps(refetch)
      const onBillingAddressSet = useUpdateBillingAddress(accountGuid, refetch)
      const onClose = useCallback(() => {
        externalOnClose()
        setPageSize('10')
        resetPage()
      }, [externalOnClose, setPageSize, resetPage])

      return (
        <>
          <OnsiteModal open size="large" onClose={onClose}>
            <OnsiteModalContent
              onClose={onClose}
              header="Locations"
              footer={
                <PaginationControls
                  className="mt-4"
                  page={page}
                  pageSize={pageSize}
                  totalItems={total}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              }
            >
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <div className="flex min-h-0 flex-col gap-2">
                  {locations.map((location, idx) => (
                    <div>
                      <LocationCard
                        key={location.locationGuid}
                        location={convertLocationToLocationCardLocation(
                          location,
                        )}
                        billingAddressGuid={billingAddressGuid}
                        onEdit={
                          editable
                            ? () => onEditLocation(locations[idx])
                            : undefined
                        }
                        onMakeBillingAddress={
                          editable
                            ? () =>
                                onBillingAddressSet(
                                  locations[idx].address.addressGuid,
                                )
                            : undefined
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </OnsiteModalContent>
          </OnsiteModal>
          {editable && upsertLocationDrawerOpen && (
            <CreateOrEditNewLocationDrawer
              accountGuid={accountGuid}
              {...editLocationProps}
            />
          )}
        </>
      )
    },
  )

type CreateOrEditNewLocationDrawerProps = {
  accountGuid: AccountGuid
  editingLocation: Location | undefined
  onClose: () => void
  onSuccess: () => void
}

const CreateOrEditNewLocationDrawer =
  React.memo<CreateOrEditNewLocationDrawerProps>(
    ({ accountGuid, editingLocation, onClose, onSuccess }) => {
      return (
        <BzDrawer
          title={!isNullish(editingLocation) ? 'Edit Location' : 'New Location'}
          icon={LocationIcon}
          rootClassName="z-[1020]"
          preferredWidth={720}
          item={{
            onCancel: onClose,
          }}
          destroyOnClose
        >
          <CreateOrEditNewAccountLocationForm
            flexRowSpaceX="space-x"
            labelClassName="semibold_14_22 grey9"
            showDivider
            showCancelSubmitButtons
            accountGuid={accountGuid}
            editingLocation={editingLocation ?? undefined}
            onLocationUpdated={onSuccess}
            onLocationAdded={onSuccess}
            onCancelButtonPressed={onClose}
          />
        </BzDrawer>
      )
    },
  )
