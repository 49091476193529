import { bzExpect, isNullish, PhotoRecordWithLinks } from '@breezy/shared'
import { Button, Skeleton } from 'antd'
import React, { Fragment, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import AccountReminders from '../../components/AccountReminders/AccountReminders'
import {
  ActivityFeed,
  ActivityFeedContext,
} from '../../components/ActivityFeed/ActivityFeed'
import { LinkedCallsListView } from '../../components/LinkedCallsListView/LinkedCallsListView'
import { MigrationJunkView } from '../../components/MigrationJunk/MigrationJunkView'
import { NotesListCard } from '../../components/Notes/NotesListCard/NotesListCard'
import BzTabs, {
  BzTab,
  useURLSettableTabIndex,
} from '../../components/Page/BzTabs/BzTabs'
import {
  AccountPhotoAlbumWidget,
  useAccountPhotoAlbumWidget,
} from '../../components/PhotoAlbumWidget/AccountPhotoAlbumWidget'
import { JobPhotoAlbumWidget } from '../../components/PhotoAlbumWidget/JobPhotoAlbumWidget'
import { PhotoDetailDrawer } from '../../components/PhotoDetailDrawer/PhotoDetailDrawer'
import { usePhotoUpload } from '../../components/Upload/AsyncPhotoUpload'
import { AsyncUploadWrapper } from '../../components/Upload/Upload'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { trpc } from '../../hooks/trpc'
import { useIntercom } from '../../hooks/useIntercom'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { ACCOUNT_DETAILS_TAB_KEY } from './AccountDetailsPageV2'
import { LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION } from './AccountDetailsPageV2.gql'
import { AccountWrapper, Editable } from './accountDetailsV2Utils'
import { AttachmentsTab } from './tabs/AttachmentsTab'

type AccountDetailsMiddlePaneProps = Editable<AccountWrapper>

export const AccountDetailsMiddlePane =
  React.memo<AccountDetailsMiddlePaneProps>(
    ({ meta, refetch, editable = true }) => {
      const { accountGuid } = meta

      const fetchMigrationJunkQuery = trpc.migrationJunk[
        'migration-junk:query'
      ].useQuery({
        accountGuid,
      })

      const migrationJunk = fetchMigrationJunkQuery.data
      const canUseIntegratedPhone = useCanUseIntegratedPhone()

      const tabs = useMemo<BzTab[]>(() => {
        const t: BzTab[] = []

        t.push({
          title: 'Reminders',
          content: <AccountReminders accountGuid={accountGuid} />,
        })

        if (canUseIntegratedPhone) {
          t.push({
            title: 'Calls',
            content: <LinkedCallsTab accountGuid={accountGuid} />,
          })
        }

        t.push(
          {
            title: 'Notes',
            content: (
              <NotesListCard
                linkData={{
                  primaryNoteType: 'ACCOUNT',
                  accountGuid,
                }}
                editable={editable}
              />
            ),
          },
          {
            title: 'Photos',
            content: (
              <PhotosTab accountGuid={accountGuid} editable={editable} />
            ),
          },
          {
            title: 'Attachments',
            content: (
              <AttachmentsTab accountGuid={accountGuid} editable={editable} />
            ),
          },
          {
            title: 'Activity',
            content: (
              <ActivityFeed
                context={ActivityFeedContext.Account}
                accountGuid={accountGuid}
              />
            ),
          },
        )

        if (migrationJunk && Object.keys(migrationJunk.junk).length > 0) {
          t.push({
            title: migrationJunk.uiLabel ?? 'Legacy Migration Data',
            content: <MigrationJunkView migrationJunk={migrationJunk} />,
          })
        }

        return t
      }, [accountGuid, canUseIntegratedPhone, editable, migrationJunk])

      const [activeTabIndex, setActiveTabIndex] = useURLSettableTabIndex(
        tabs,
        0,
        ACCOUNT_DETAILS_TAB_KEY,
      )

      const activeTab = tabs[activeTabIndex]

      return (
        <div className="flex flex-col space-y-3 p-6">
          <BzTabs
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
          />
          {activeTab.content}
        </div>
      )
    },
  )

type PhotosTabProps = {
  accountGuid: string
  editable?: boolean
}

const PhotosTab = React.memo<PhotosTabProps>(
  ({ accountGuid, editable = false }) => {
    const [editingPhoto, setEditingPhoto] =
      useState<PhotoRecordWithLinks | null>(null)
    useIntercom({ isLauncherVisible: isNullish(editingPhoto) })

    const { accountPhotos, jobPhotos, total, refetch, fetching } =
      useAccountPhotoAlbumWidget(accountGuid)
    const useUploadProps = usePhotoUpload(
      () => {
        refetch()
      },
      { accountGuid },
    )

    return (
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center justify-between">
          <span className="font-semibold">
            {total === 1 ? `${total} photo` : `${total} photos`}
          </span>

          {editable && (
            <div>
              <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
                <Button type="link" className="text-sm">
                  + Upload Photo
                </Button>
              </AsyncUploadWrapper>
            </div>
          )}
        </div>

        {fetching && (
          <div className="flex w-full flex-col gap-3">
            {Array.from({ length: 3 }).map((_, i) => (
              <Skeleton.Node
                active
                style={{
                  width: '100%',
                  height: '400px',
                  borderRadius: '12px',
                }}
              >
                <></>
              </Skeleton.Node>
            ))}
          </div>
        )}

        {!fetching && accountPhotos && accountPhotos.length > 0 && (
          <AccountPhotoAlbumWidget
            photos={accountPhotos}
            title="Account Photos"
            numPhotos={accountPhotos.length}
            options={{ thumbnailSize: 'large' }}
            onPhotoClick={photo => {
              const record = accountPhotos.find(
                curr => curr.photoGuid === photo.photoGuid,
              )
              if (!record) {
                return
              }

              setEditingPhoto({
                cdnUrl: record.url,
                createdAt: bzExpect(record.createdAt),
                createdByUserGuid: record.createdByUserGuid,
                photoGuid: record.photoGuid,
                resourceUrn: record.resourceUrn,
                accountGuid,
              })
            }}
          />
        )}

        {!fetching && jobPhotos && (
          <>
            {Object.entries(jobPhotos).map(([jobGuid, data]) => (
              <Fragment key={jobGuid}>
                {data.photos.length > 0 && (
                  <JobPhotoAlbumWidget
                    jobGuid={jobGuid}
                    photos={data.photos}
                    title={`Job ${data.job.displayId} ${data.job.jobTypeName}`}
                    location={data.job.location}
                    numPhotos={data.photos.length}
                    lastUpdatedAt={data.photos[0].updatedAt}
                    options={{
                      showLocation: true,
                      thumbnailSize: 'large',
                    }}
                    onPhotoClick={photo => {
                      const record = jobPhotos[jobGuid].photos.find(
                        curr => curr.photoGuid === photo.photoGuid,
                      )
                      if (!record) {
                        return
                      }

                      setEditingPhoto({
                        cdnUrl: record.url,
                        createdAt: bzExpect(record.createdAt),
                        createdByUserGuid: record.createdByUserGuid,
                        photoGuid: record.photoGuid,
                        resourceUrn: record.resourceUrn,
                        accountGuid,
                      })
                    }}
                  />
                )}
              </Fragment>
            ))}
          </>
        )}
        {editable && editingPhoto && (
          <PhotoDetailDrawer
            photo={editingPhoto}
            onClose={() => {
              setEditingPhoto(null)
            }}
            onDelete={() => {
              setEditingPhoto(null)
              refetch()
            }}
            editable={editable}
          />
        )}
      </div>
    )
  },
)

type LinkedCallsTabProps = {
  accountGuid: string
}
const LinkedCallsTab = React.memo<LinkedCallsTabProps>(({ accountGuid }) => {
  const companyGuid = useExpectedCompanyGuid()

  const [{ data: callsData, fetching: callsFetching }] = useSubscription({
    query: LINKED_CALLS_FOR_ACCOUNT_SUBSCRIPTION,
    variables: {
      accountGuid,
      companyGuid,
    },
  })

  return (
    <LinkedCallsListView
      calls={callsData?.accounts[0].integratedPhoneCalls ?? []}
      isLoading={callsFetching}
    />
  )
})
