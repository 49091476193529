import {
  Address,
  BzAddress,
  BzDateTime,
  formatUsc,
  IsoDateString,
} from '@breezy/shared'
import { Col, Typography } from 'antd'
import { memo, useMemo } from 'react'
import { InvoiceDetailsLink } from '../../components/Links/EntityLinks'
import { SmallCard } from '../../elements/Card/SmallCard'
import StatusTag from '../../elements/StatusTag/StatusTag'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { KeyValueRow } from './components/KeyValueRow'

export type AccountJobCompletedCard = {
  displayId: number
  jobType: {
    name: string
  }
  jobLifecycleStatusUpdatedAt: IsoDateString
  jobLifecycleStatus: {
    specialStatus?: string
  }
  jobInvoices: {
    invoice: {
      invoiceGuid: string
      totalUsc: number
    }
  }[]
  location: {
    displayName?: string
    address: Address
  }
  appointments: {
    assignments: {
      technician: {
        firstName: string
        lastName: string
      }
    }[]
  }[]
}

type AccountJobCompletedMiniCardProps = {
  accountJob: AccountJobCompletedCard
}

export const AccountJobCompletedMiniCard =
  memo<AccountJobCompletedMiniCardProps>(({ accountJob }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const invoiceTotalUsc = useMemo(() => {
      return Math.trunc(
        accountJob.jobInvoices.reduce(
          (acc, curr) => acc + curr.invoice.totalUsc,
          0,
        ),
      )
    }, [accountJob.jobInvoices])

    const techniciansString = useMemo(() => {
      const allNames = accountJob.appointments.flatMap(apt =>
        apt.assignments.map(
          a => `${a.technician.firstName} ${a.technician.lastName[0]}.`,
        ),
      )
      const uniqueSortedNames = Array.from(new Set(allNames)).sort()
      return allNames.length > 0 ? uniqueSortedNames.join(', ') : undefined
    }, [accountJob.appointments])

    const header = (
      <div className="space-between flex flex-row flex-nowrap">
        <Typography.Text className="pt-0.5">{`${accountJob.jobType.name} (#${accountJob.displayId})`}</Typography.Text>
        <Col className="py-0.5">
          <StatusTag text="Completed" color="green" border="regular" />
        </Col>
      </div>
    )

    return (
      <SmallCard header={header}>
        <div className="flex flex-col gap-y-1">
          <KeyValueRow
            label="Location"
            value={
              accountJob.location.displayName ??
              BzAddress.create(
                accountJob.location.address,
              ).streetAddressLine1And2Condensed()
            }
          />
          <KeyValueRow
            label="Completed At"
            value={
              accountJob.jobLifecycleStatusUpdatedAt
                ? BzDateTime.fromIsoString(
                    accountJob.jobLifecycleStatusUpdatedAt,
                    tzId,
                  ).toHumanFriendlyMonthDayYear()
                : undefined
            }
          />
          <KeyValueRow
            label="Invoice Total"
            value={invoiceTotalUsc > 0 ? formatUsc(invoiceTotalUsc) : undefined}
          />
          <KeyValueRow label="Technicians" value={techniciansString} />
          {/* TODO: BZ-3640: Replace with Invoice Popup */}
          {accountJob.jobInvoices.length > 0 && (
            <InvoiceDetailsLink
              className="mt-2"
              guid={accountJob.jobInvoices[0].invoice.invoiceGuid}
            />
          )}
        </div>
      </SmallCard>
    )
  })
