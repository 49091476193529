import {
  BzDateFns,
  BzDateTime,
  formatUscWholeDollars,
  InvoiceV2Status,
  IsoDateString,
  SpecialLifecycleStatus,
} from '@breezy/shared'
import { faHouseUser } from '@fortawesome/pro-regular-svg-icons'
import { memo, useMemo } from 'react'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { StaticHeightOptionCardHeader } from './StaticHeightOptionCardHeader'

type AccountCardHeaderAccount = {
  accountDisplayName: string
  createdAt: IsoDateString
  jobs: {
    jobGuid: string
    jobInvoices: {
      invoice: {
        status: InvoiceV2Status
        totalUsc: number
      }
    }[]
    jobLifecycleStatusUpdatedAt: IsoDateString
    jobLifecycleStatus: {
      specialStatus?: SpecialLifecycleStatus
    }
  }[]
  tags: {
    tag: {
      name: string
      color: string
    }
  }[]
}

type Props = {
  account: AccountCardHeaderAccount
  numTagsToShow?: number
}

export const AccountCardHeader = memo<Props>(({ account, numTagsToShow }) => {
  const tzId = useExpectedCompanyTimeZoneId()

  // NOTE: Technically this doesn't factor in refunds
  // Factoring in Refunds would be the most correct, but would require pulling in
  // all the payment, refunds and statuses instead of simply the invoices
  const accountSummaryChipTags = useMemo(() => {
    const tags: { label: string; value: string }[] = []
    const jobs = account.jobs
    const jobValuesByJobGuid: Record<string, number> = {}
    jobs.forEach(j => {
      jobValuesByJobGuid[j.jobGuid] = j.jobInvoices
        .filter(ji => ji.invoice.status === 'PAID')
        .reduce((acc, inv) => acc + inv.invoice.totalUsc, 0)
    })

    const ltvUsc = Object.values(jobValuesByJobGuid).reduce(
      (acc, jv) => acc + jv,
      0,
    )
    tags.push({ label: 'LTV', value: `${formatUscWholeDollars(ltvUsc)}` })
    tags.push({ label: '# of Jobs', value: `${jobs.length}` })
    if (jobs.length > 0) {
      const averageJobValue = Math.trunc(ltvUsc / jobs.length)
      tags.push({
        label: 'Avg. Job',
        value: `${formatUscWholeDollars(averageJobValue)}`,
      })
    }
    if (jobs.length > 0) {
      const lastCompletedJob = jobs.find(
        job => job.jobLifecycleStatus.specialStatus === 'Completed',
      )
      if (lastCompletedJob && lastCompletedJob.jobLifecycleStatusUpdatedAt) {
        tags.push({
          label: 'Last Job',
          value: `${BzDateTime.fromIsoString(
            lastCompletedJob.jobLifecycleStatusUpdatedAt,
            tzId,
          ).toHumanFriendlyMonthDayShortenedYear()}`,
        })
      }
    }

    return tags
  }, [account, tzId])

  return (
    <StaticHeightOptionCardHeader
      faIcon={faHouseUser}
      iconBgColor="#f5f5f5"
      iconClassName="text-[20px] text-[#565656]"
      title={account.accountDisplayName}
      description={`Added in ${BzDateFns.formatFromISO(
        account.createdAt,
        'yyyy',
        tzId,
      )}`}
      tags={accountSummaryChipTags}
      numTagsToShow={numTagsToShow}
    />
  )
})
