import { Account } from '@breezy/shared'
import { memo, useCallback, useMemo } from 'react'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import AccountSearchAutocomplete from '../../components/ProgressiveJobCreationModal/AccountSearchAutocomplete/AccountSearchAutocomplete'
import { Tag } from '../../components/Tags/Tag'
import { AccountContactMiniCard } from './AccountContactMiniCard'
import { AccountJobCompletedMiniCard } from './AccountJobCompletedMiniCard'
import { AccountCardHeader } from './components/AccountCardHeader'
import { SectionHeaderText } from './components/SectionHeaderText'
import { WizardColum } from './components/WizardColum'
import { useMaintenancePlanWizardSelectedAccountInfo } from './MaintenancePlanV3Utils'

type SelectAccountPanelProps = {
  selectedAccount: Account
  onAccountSelect: (account: Account | undefined) => void
}

export const SelectAccountPanel = memo<SelectAccountPanelProps>(
  ({ selectedAccount, onAccountSelect }) => {
    const { selectedAccountInfo, fetching } =
      useMaintenancePlanWizardSelectedAccountInfo(selectedAccount.accountGuid)

    // Primary contact sorted first
    const sortedContacts = useMemo(() => {
      return [...selectedAccount.accountContacts].sort((a, b) => {
        return (b.primary ? 1 : 0) - (a.primary ? 1 : 0)
      })
    }, [selectedAccount.accountContacts])
    const firstContact = useMemo(
      () => (sortedContacts.length > 0 ? sortedContacts[0] : undefined),
      [sortedContacts],
    )

    const handleAccountSelect = useCallback(
      (account: Account) => {
        if (selectedAccount?.accountGuid !== account.accountGuid) {
          onAccountSelect(account)
        }
      },
      [selectedAccount, onAccountSelect],
    )

    const lastCompletedJob = useMemo(() => {
      if (!selectedAccountInfo) {
        return undefined
      }
      if (selectedAccountInfo.jobs.length === 0) {
        return undefined
      }
      return selectedAccountInfo.jobs[0]
    }, [selectedAccountInfo])

    const sections = useMemo<SectionedSection[]>(() => {
      if (!selectedAccountInfo) {
        return [
          <p>An unknown loading error has occurred. Try searching again.</p>,
        ]
      }
      return [
        {
          content: <AccountCardHeader account={selectedAccountInfo} />,
        },
        <div className="grid gap-4">
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            {firstContact && (
              <div>
                <h5 className="mb-3 text-base font-semibold">Contacts</h5>
                <AccountContactMiniCard accountContact={firstContact} />
              </div>
            )}
            <div>
              <h5 className="mb-3 text-base font-semibold">Tags</h5>
              <div className="flex flex-wrap gap-y-2">
                {selectedAccountInfo.tags.map(({ tag }) => (
                  <Tag tag={tag} tagStyleVersion="v2" key={tag.tagGuid} />
                ))}
              </div>
            </div>
          </div>
          {lastCompletedJob && (
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <div>
                <h5 className="mb-3 text-base font-semibold">Last Job</h5>
                <AccountJobCompletedMiniCard accountJob={lastCompletedJob} />
              </div>
            </div>
          )}
        </div>,
      ]
    }, [firstContact, lastCompletedJob, selectedAccountInfo])

    return (
      <WizardColum>
        <SectionHeaderText text="Select Account" />
        <AccountSearchAutocomplete
          currentAccount={selectedAccount}
          setCurrentAccount={handleAccountSelect}
          showLabel={false}
        />
        <SectionedCard
          heightClassName="min-h-[312px]"
          sections={sections}
          loading={fetching}
        />
      </WizardColum>
    )
  },
)
