import { ComprehensiveJobDetails } from '@breezy/backend/src/application-types'
import { MaintenancePlanStatus, jobClassDisplayNames } from '@breezy/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MaintenancePlanDiscountGradientClassName } from '../../pages/MaintenancePlanDetailsPage/MaintenancePlanStyling'
import { MaintenancePlanIcon } from '../../utils/feature-icons'

type MaintenancePlanJobDiscountBannerProps = {
  readonly job?: ComprehensiveJobDetails
}

const getDiscountRate = (job?: ComprehensiveJobDetails): number | undefined => {
  if (!job) return undefined

  const jobClass = job.getJobType().jobClass
  const maintenancePlan = job.getMaintenancePlan()
  if (
    !maintenancePlan ||
    maintenancePlan.status !== MaintenancePlanStatus.ACTIVE
  )
    return undefined

  return maintenancePlan.definition?.discounts?.find(
    d => d.discountJobClass === jobClass,
  )?.discountRate
}

const MaintenancePlanJobDiscountBanner = ({
  job,
}: MaintenancePlanJobDiscountBannerProps) => {
  const padding = 'py-2'
  const discountRate = getDiscountRate(job)
  const jobClass = job?.getJobType().jobClass

  return (
    <>
      {discountRate && jobClass ? (
        <div
          className={`row flex-between center-children-v mb-3 flex rounded-md shadow-md ${padding} px-4 ${MaintenancePlanDiscountGradientClassName}`}
        >
          <div className="row row center-children-v flex w-full">
            <FontAwesomeIcon
              icon={MaintenancePlanIcon}
              style={{ color: '#fff', fontSize: 18, marginRight: 8 }}
            />
            <div className="semibold_12_22 text-white">
              Maintenance Plan Discount:{' '}
              <b>
                {discountRate.toLocaleString('en-US', {
                  style: 'percent',
                  minimumFractionDigits: 0,
                })}{' '}
                {jobClassDisplayNames[jobClass]} discount
              </b>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default MaintenancePlanJobDiscountBanner
