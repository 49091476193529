import { zodResolver } from '@hookform/resolvers/zod'
import { Form, Input } from 'antd'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { OnsiteConfirmModal } from 'src/adam-components/OnsiteModal/OnsiteModal'
import { z } from 'zod'

const fileEditModalFormSchema = z.object({
  fileGuid: z.string().uuid(),
  fileName: z.string().min(1).max(60),
})

type FileEditModalForm = z.infer<typeof fileEditModalFormSchema>

export interface FileEditModalProps {
  open: boolean
  fileGuid: string
  onSubmit: (data: FileEditModalForm) => void
  onClose: () => void
  defaultValues?: Partial<Omit<FileEditModalForm, 'fileGuid'>>
  loading?: boolean
  saveText?: string
}

export const FileEditModal = (props: FileEditModalProps) => {
  const form = useForm<FileEditModalForm>({
    resolver: zodResolver(fileEditModalFormSchema),
    defaultValues: {
      fileGuid: props.fileGuid,
      ...(props.defaultValues ? props.defaultValues : {}),
    },
  })

  const onSubmit = form.handleSubmit(data => props.onSubmit(data))

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    form.reset({
      fileGuid: props.fileGuid,
      ...(props.defaultValues ? props.defaultValues : {}),
    })
  }, [form, props.defaultValues, props.fileGuid])

  return (
    <form ref={formRef} onSubmit={onSubmit}>
      <OnsiteConfirmModal
        header="Edit Attachment"
        open={props.open}
        onConfirm={() => formRef.current?.requestSubmit()}
        onCancel={props.onClose}
        confirmDisabled={props.loading}
        confirmText={props.saveText}
      >
        <Controller
          control={form.control}
          name="fileName"
          render={({ field }) => (
            <label className="flex flex-col gap-1">
              <span>File Name</span>

              <Form.Item
                validateStatus={
                  form.formState.errors.fileName?.message ? 'error' : ''
                }
                help={
                  form.formState.errors.fileName?.message
                    ? form.formState.errors.fileName.message
                    : ''
                }
                className={
                  form.formState.errors.fileName?.message ? 'pb-4' : ''
                }
              >
                <Input {...field} placeholder="Enter file name here..." />
              </Form.Item>
            </label>
          )}
        />
      </OnsiteConfirmModal>
    </form>
  )
}
