import { AccountLocation } from '@breezy/shared'
import classNames from 'classnames'
import { memo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import useIsMobile from '../../hooks/useIsMobile'
import { AccountCardHeader } from './components/AccountCardHeader'
import { LocationCardHeader } from './components/LocationCardHeader'
import { RightLinkButton } from './components/RightLinkButton'
import { useMaintenancePlanWizardSelectedAccountInfo } from './MaintenancePlanV3Utils'

type Props = {
  selectedAccountGuid: string
  selectedLocation: AccountLocation
  goToSelectAccountAndLocationStep: () => void
}

export const SelectedAccountAndLocationPanel = memo<Props>(
  ({
    selectedAccountGuid,
    selectedLocation,
    goToSelectAccountAndLocationStep,
  }) => {
    const isMobile = useIsMobile()
    const { selectedAccountInfo } =
      useMaintenancePlanWizardSelectedAccountInfo(selectedAccountGuid)
    return (
      <div className="flex w-full flex-col">
        <div
          className={classNames(
            'mb-4 flex w-full flex-row items-center justify-center',
          )}
        >
          <div className="mr-4 flex-1 text-[20px] font-semibold leading-[28px] text-[rgba(0,0,0,0.88)]">
            Account & Location
          </div>

          <RightLinkButton
            onClick={goToSelectAccountAndLocationStep}
            title={isMobile ? 'Change' : 'Change Account or Location'}
          />
        </div>
        <div
          className={classNames('grid gap-3', {
            'grid-cols-1': isMobile,
            'grid-cols-2': !isMobile,
          })}
        >
          <div
            className={classNames(
              'rounded-xl border border-solid border-bz-gray-500 shadow-sm',
              {
                'p-4': isMobile,
                'p-6': !isMobile,
              },
            )}
          >
            {selectedAccountInfo ? (
              <AccountCardHeader
                account={selectedAccountInfo}
                numTagsToShow={2}
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
          <div
            className={classNames(
              'rounded-xl border border-solid border-bz-gray-500 shadow-sm',
              {
                'p-4': isMobile,
                'p-6': !isMobile,
              },
            )}
          >
            <LocationCardHeader
              accountLocation={selectedLocation}
              numTagsToShow={1}
            />
          </div>
        </div>
      </div>
    )
  },
)
