import { isNullish } from '@breezy/shared'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import useIsMobile from '../../hooks/useIsMobile'
import { Card, CardProps } from '../Card/Card'
import { SectionedContent, SectionedContentProps } from './SectionedContent'

type SectionedCardProps = SectionedContentProps &
  CardProps & {
    accentBarColor?: string
    accentBarBody?: ReactNode
    anchoredFooter?: React.ReactNode
    loading?: boolean
  }

export const SectionedCard = React.memo<SectionedCardProps>(
  ({
    className,
    accentBarColor,
    noBorder = false,
    heightClassName = 'h-full',
    anchoredFooter,
    loading = false,
    dataTestId,
    style,
    small,
    shadowClassName,
    ...rest
  }) => {
    const isMobile = useIsMobile()

    return (
      <Card
        style={style}
        className={className}
        heightClassName={heightClassName}
        dataTestId={dataTestId}
        paddingClassName=""
        shadowClassName={shadowClassName}
        noBorder={noBorder}
      >
        {accentBarColor && (
          <div
            className={classNames(
              'absolute inset-0 z-20 flex h-[10px] flex-col',
              {
                'h-fit': !isNullish(rest.accentBarBody),
                'p-1': !isNullish(rest.accentBarBody),
              },
            )}
            style={{ backgroundColor: accentBarColor }}
          >
            {rest.accentBarBody}
          </div>
        )}
        {loading ? (
          <div className="center-vh flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : null}
        {!loading && (
          <>
            {/**
             * This is really stupid. Since we want the accent bar affixed to
             *  the top of sectioned card, it has to have a position of
             * absolute. This means any top padding we want to apply to the
             * content to account for this header, we need to know the height
             * of the accent bar (including its children). I tried doing this
             * with a useRef but the ref kept getting set to null after render,
             * making it unable to get the offset height of the element.
             * Here we just rerender the element again to push it down to add
             * the padding. xD
             */}
            {accentBarColor && (
              <div
                className={classNames('inset-0 z-20 flex h-[10px] flex-col', {
                  'h-fit': !isNullish(rest.accentBarBody),
                  'p-1': !isNullish(rest.accentBarBody),
                })}
              >
                {rest.accentBarBody}
              </div>
            )}

            <div className="flex flex-1 flex-col overflow-auto">
              <SectionedContent
                className={small ? '*:px-3' : isMobile ? '*:px-4' : '*:px-6'}
                small={small}
                {...rest}
              />
            </div>
          </>
        )}
        {anchoredFooter ? (
          <div
            className={classNames(
              'border-0 border-t border-solid border-bz-border bg-bz-gray-100',
              isMobile ? 'px-4 pb-4 pt-2' : 'px-6 py-5',
            )}
          >
            {anchoredFooter}
          </div>
        ) : null}
      </Card>
    )
  },
)
